<template>
  <action-dispatcher
    action="courses/getDetails"
    :parameters="$route.params.id"
    ref="coursesDispatcher"
    class="view-course d-flex justify-content-center"
  >
    <div class="container-fluid px-lg-5 py-5">
      <header class="course-header text-light p-4">
        <div class="media flex-wrap">
          <img :src="course.cover" class="align-self-center mr-3" />
          <div
            class="media-body d-flex align-items-center align-self-center flex-wrap"
          >
            <h1 class="py-2 m-0 font-weight-bold flex-fill pr-4">
              {{ course.title }}
            </h1>
            <div v-if="currentLesson" class="py-2">
              <router-link
                class="btn btn-success px-5"
                :to="{ name: 'lesson', params: { lesson: currentLesson.id } }"
              >
                <span v-if="currentLesson.number === 1">Iniciar curso</span>
                <span v-else>Continuar curso</span>
              </router-link>
            </div>
          </div>
        </div>
      </header>

      <div class="mt-4 row">
        <div :class="isBig ? 'col-12' : 'col-xl-8 col-lg-7 pr-lg-5'">
          <div class="d-flex mb-5 flex-wrap">
            <div class="card border-0 mr-3 my-2">
              <div class="card-body px-4">
                <img src="../assets/images/worldwide.svg" class="mr-2" />
                <strong>Formação em idiomas</strong>
              </div>
            </div>

            <div class="card border-0 mr-3 my-2">
              <div class="card-body px-4">
                <img src="../assets/images/formation.svg" class="mr-2" />
                <strong>{{ numLessons }} aulas</strong>
              </div>
            </div>

            <div class="card border-0 my-2">
              <div class="card-body px-4">
                <img src="../assets/images/time.svg" class="mr-2" />
                <strong>{{ time }}h de aula</strong>
              </div>
            </div>
          </div>

          <div class="course-description" v-html="course.description" />
        </div>
        <div :class="isBig ? 'col-12 pt-4' : 'col-xl-4 col-lg-5'">
          <lessons-list :big="isBig" class="order-lg-2" :course="course" />
        </div>
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import LessonsList from "../components/course/LessonsList";
import get from "lodash/get";
import { mapState } from "vuex";

export default {
  components: { LessonsList },
  computed: {
    ...mapState("courses", ["course"]),
    isBig() {
      return parseInt(this.$route.params.id) === 8;
    },
    currentLesson() {
      return (
        this.course &&
        this.course.lessons &&
        this.course.lessons.find(
          lesson => !lesson.progress || !lesson.progress.finished_at
        )
      );
    },
    numLessons() {
      return get(this.course, "lessons.length", 0);
    },
    time() {
      return Math.round(this.numLessons / 6);
    }
  }
};
</script>

<style lang="scss" scoped>
.view-course {
  min-height: calc(100vh - 56px);

  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
  }

  .course-description {
    font-size: 18px;
    color: #555;
  }

  .course-header {
    background: #ef4c56;
    border-radius: 16px;

    img {
      height: 120px;
      border-radius: 16px;
    }

    a {
      white-space: nowrap;
    }
  }

  a {
    line-height: 34px;
  }

  button {
    i {
      font-size: 22px;
    }
  }

  .media {
    @media screen and (max-width: 767px) {
      img,
      .btn,
      .media-body > * {
        width: 100%;
        height: auto;
      }
      h1 {
        font-size: 24px;
      }
    }
  }

  .card {
    strong {
      white-space: nowrap;
    }
  }
}
</style>
