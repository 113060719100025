<template>
  <aside class="course-lessons-list p-4">
    <h6 class="mb-4">Counteúdo programático</h6>

    <div class="row">
      <div
        v-for="(lesson, i) in lessons"
        :key="i"
        :class="{ 'col-lg-4': big, 'col-12': !big }"
      >
        <router-link
          class="card text-decoration-none text-dark mb-2"
          :to="{ name: 'lesson', params: { lesson: lesson.id } }"
        >
          <div class="card-body d-flex align-items-center">
            <span class="flex-fill pr-2">
              {{ lesson.name }}
            </span>

            <div
              v-if="lesson.progress && lesson.progress.finished_at"
              class="circle check"
            >
              <img src="https://img.icons8.com/metro/16/FFFFFF/checkmark.png" />
            </div>

            <template v-else>
              <div class="circle play-current">
                <img src="https://img.icons8.com/metro/16/EF4D57/play.png" />
              </div>
              <div class="circle play-next">
                <img src="https://img.icons8.com/metro/16/C8D6E5/play.png" />
              </div>
            </template>
          </div>
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  props: {
    course: {
      type: Object,
      required: true
    },
    big: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchTerm: ""
    };
  },
  computed: {
    lessons() {
      return this.course.lessons.filter(
        item =>
          item.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0
      );
    }
  },
  methods: {
    duration(lesson) {
      if (!lesson.duration) return "";
      let [h, m, s] = lesson.duration.split(":");
      if (h === "00") {
        return `${m}:${s}`;
      } else {
        return lesson.duration;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.course-lessons-list {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  background: white;

  .card {
    border-right: none;
    border-left: none;
    background: transparent;
    text-align: left;
    font-size: 14px;
    background: #fafafa;
    border: none;
    font-weight: bold;
  }

  a:hover {
    background: rgba(100, 100, 100, 0.05);
  }

  .circle {
    min-width: 24px;
    min-height: 24px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 16px;
      height: 16px;
    }
  }
  .check {
    background: #04c56b;
  }
  .play-current {
    border: 1px solid #ef4d57;
    display: none;
  }
  .play-next {
    border: 1px solid #c8d6e5;
  }

  .router-link-exact-active {
    .play-current {
      display: flex;
    }
    .play-next {
      display: none;
    }
  }
}
</style>
